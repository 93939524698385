import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode'
import { MainService } from 'src/app/provider/main.service';
export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  isBadge: true,
  badgeKey: any,
  children?: Navigation[];
}


export interface Navigation extends NavigationItem {
  
  children?: NavigationItem[];
}
function getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  } catch(Error) {
    return null;
  }
}
let adminDetail = getDecodedAccessToken(localStorage.getItem('token'))
console.log(adminDetail?.userType);


let NavigationItems = []
if(adminDetail?.userType == 'ADMIN'){
   NavigationItems = [
    {
  
      type: 'group',
  
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/home',
          classes: 'nav-item',
          icon: 'feather icon-activity'
        },
        {
          id: 'project',
          title: 'Project Management',
          type: 'item',
          url: '/project-management/project',
          classes: 'nav-item',
          icon: 'feather icon-home'
        },
        {
          id: 'developer-managemet',
          title: 'Developer Management',
          type: 'item',
          url: '/developer-managemet/developer',
          classes: 'nav-item',
          icon: 'feather icon-user'
        },
        {
          id: 'brokers-management',
          title: 'Broker Management',
          type: 'item',
          url: '/broker-management/brokers-management',
          classes: 'nav-item',
          icon: 'feather icon-users',
          isBadge: true,
          badgeKey: 'brokerNotificationCounts'
        },
        {
          id: 'kyc',
          title: 'KYC Management',
          type: 'item',
          url: '/kyc-management/kyc',
          classes: 'nav-item',
          icon: 'feather icon-user-check',
          isBadge: true,
          badgeKey: 'kycNotificationCounts'
        },
  
        {
          id: 'category',
          title: 'Category Management',
          type: 'item',
          url: '/category-management/category',
          classes: 'nav-item',
          icon: 'feather icon-tag'
        },
        {
          id: 'reward',
          title: 'Reward Management',
          type: 'item',
          url: '/reward-managemet/reward',
          classes: 'nav-item',
          icon: 'feather icon-rewards',
          isBadge: true,
          badgeKey: 'rewardNotificationCounts'
  
        },
        {
          id: 'loan',
          title: 'Loan Management',
          type: 'item',
          url: '/loan-management/loan',
          classes: 'nav-item',
          icon: 'feather icon-loan'
        },
        
        {
          id: 'broad-cast',
          title: 'Broadcast Management',
          type: 'item',
          url: '/broad-cast-mangement/broad-cast-list',
          classes: 'nav-item',
          icon: 'feather icon-airplay'
        },
        {
          id: 'static',
          title: 'Static Management',
          type: 'item',
          url: '/static',
          classes: 'nav-item',
          icon: 'feather icon-settings'
        },
        {
          id: 'profile',
          title: 'Profile Management',
          type: 'item',
          url: '/myProfile/profile',
          classes: 'nav-item',
          icon: 'feather icon-user'
        },
        {
          id: 'contact',
          title: 'Contact Us',
          type: 'item',
          url: '/contact/contact-us-list',
          classes: 'nav-item',
          icon: 'feather icon-phone'
        },
  
        {
          id: 'faq',
          title: "FAQ",
          type: 'item',
          url: '/faq',
          classes: 'nav-item',
          icon: 'feather icon-question'
        },
        
  
      ]
    },
  
    
  ];
}
else{
   NavigationItems = [
    {
  
      type: 'group',
  
      children: [
        {
          id: 'brokers-management',
          title: 'Broker Management',
          type: 'item',
          url: '/broker-management/brokers-management',
          classes: 'nav-item',
          icon: 'feather icon-users',
          isBadge: false,
          badgeKey: 'brokerNotificationCounts'
        },
      ]
    },
  
   
  ];
}



@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
  constructor(private service : MainService){
    this.service.loginData.subscribe((res)=>{
      
    })
  }
}
