import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const CHAT_URL = environment.socketUrl;
// export const WS_ENDPOINT = 'ws://172.16.1.172:1940';
// const subject = webSocket("ws://172.16.1.172:1940");

const Message = {
  "token": localStorage.getItem('token'),
  "requestType": "NotificationCount"

}
@Injectable({
  providedIn: 'root',

})
export class WebSocketService {

  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<any>;
  public data = new BehaviorSubject({});

  constructor() {
    this.connect(CHAT_URL)
  }


  onSend() {

  }
  public connect(url) {
    if (!this.subject) {
      this.create(url);
      console.log("Successfully connected: " + url);
    }


    return this.subject;
  }

  private create(url) {
    
    console.log('url------',url)
    let web = new WebSocket(url)
    console.log('web0000', web)
    
    web.onopen = () => {
      const dataToSend = {

        requestType: "NotificationCount",
        token: localStorage.getItem('token')
      };

      web.send(JSON.stringify(dataToSend));
      web.onmessage = async (event) => {
        // && event.data !== "null"
        if (event.data !== "[object Promise]") {
          let newObj = JSON.parse(event.data);
          // console.log('newObj---', newObj)
          // // this.data.next(newObj)
          this.data.next(newObj.result)
        }
      };
    };
  }
}

