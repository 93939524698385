<app-navigation class="pcoded-navbar"
  [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.flatConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
  (onNavMobCollapse)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light"
  (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-content"
    [ngClass]="{'container': this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'}">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>

  </div>

</div>
<div class="main-footer">
  <!-- <span>Admin panel Developed By Mobiloitte Technologies</span> -->
  <span>Copyright &copy; 2022 Real Estate | All Rights Reserved</span>
</div>

<app-configuration></app-configuration>